import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Accordion, AccordionItem } from 'react-light-accordion'
import { useSelector } from 'react-redux'
import 'react-light-accordion/demo/css/index.css'
import Fade from "react-reveal/Fade"

import Layout from "../layouts/App"
import SEO from "../components/seo"

import Spotlight from '../components/Spotlight'

export default ({pageContext}) => {
    return(
    <Layout>
        <SEO title="Jobs" image={pageContext.node.acf.spotlightImage.sourceUrl} description={pageContext.node.acf.metaPageDescription}/>
        <article className="about_page">
            <Fade bottom distance={`100px`}>
                <Spotlight image={pageContext.node.acf.spotlightImage?.sourceUrl} />
            </Fade>
            <section className="container-fluid-custom-only content-body jobs min--width--coloumn">
                <Fade bottom distance={`100px`}>
                    <h1>{pageContext.node.acf.title}</h1>
                    <div dangerouslySetInnerHTML={{__html:pageContext.node.content}} />
                </Fade>
            </section>
            <section className="container-fluid-custom-only accordion__Wrap min--width--coloumn">
                <StaticQuery query={graphql`
                    {
                        jobsEN: allWpJob (
                            filter:{languageCode:{eq:"en"}}
                            sort: {fields: modified, order: DESC}
                        ) {
                            edges {
                                node {
                                    id
                                    title
                                    date
                                    slug
                                    languageCode
                                    content
                                    acf {
                                        jobExcerpt
                                        jobQualifications
                                        jobLink
                                    }
                                }
                            }
                        }
                        jobsJA: allWpJob(
                            filter:{languageCode:{eq:"ja"}}
                            sort: {fields: modified, order: DESC}
                        ) {
                            edges {
                                node {
                                    id
                                    title
                                    date
                                    slug
                                    languageCode
                                    content
                                    acf {
                                        jobExcerpt
                                        jobQualifications
                                        jobLink
                                    }
                                }
                            }
                        }
                        jobsKO: allWpJob(
                            filter:{languageCode:{eq:"ko"}}
                            sort: {fields: modified, order: DESC}
                        ) {
                            edges {
                                node {
                                    id
                                    title
                                    date
                                    slug
                                    languageCode
                                    content
                                    acf {
                                        jobExcerpt
                                        jobQualifications
                                        jobLink
                                    }
                                }
                            }
                        }
                    }
                `} render={props => {
                    const langKey = useSelector(state => state.langKey.language)
                    const urlBase = langKey === "ja" ? "" : `/${langKey}`
                    if(pageContext.node.languageCode === 'ja'){
                        const valueCheck = props.jobsJA.edges
                        if( valueCheck.length != 0 )
                        {
                            return(
                            <Fade bottom speed={450} delay={25}>
                                <h2>Current openings</h2>
                                <Accordion atomic={true}>
                                    {props.jobsJA.edges.map(jobs => {
                                        if(jobs.node.acf.jobLink){
                                            return(
                                                <div className='jobsAlternateTitle' key={jobs.node.id}>
                                                    <a href={jobs.node.acf.jobLink} target="_blank">
                                                        {jobs.node.title}
                                                        <span className='afterSvg'>
                                                            <svg width="17px" height="17px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg"><path d="m630 0-140 17.5 42.664 42.664-184.85 184.85 37.188 37.188 184.85-184.85 42.664 42.664 17.5-140zm-525 52.5c-8.4531 0-18.324 3.0117-25.164 9.8516-6.8438 6.8438-9.8516 16.695-9.8516 25.164v437.5c0 8.4531 3.0117 18.324 9.8516 25.164 6.8438 6.8438 16.695 9.8516 25.164 9.8516h437.5c8.4531 0 18.324-3.0117 25.164-9.8516 6.8438-6.8438 9.8516-16.695 9.8516-25.164v-262.5h-52.5v245h-402.5v-402.5h262.5v-52.5h-280z"/></svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            )   
                                        }else{
                                            return(
                                                <AccordionItem title={jobs.node.title} key={jobs.node.id}>
                                                    <div className="content" dangerouslySetInnerHTML={{__html:jobs.node.content}} />
                                                </AccordionItem>
                                            
                                            )   
                                        }
                                    })}
                                </Accordion>
                            </Fade>
                            )
                        }
                    }
                    if(pageContext.node.languageCode === 'en'){
                        const valueCheck = props.jobsEN.edges
                            if( valueCheck.length != 0 )
                            {
                                return(
                                <Fade bottom speed={450} delay={25}>
                                    <h2>Current openings</h2>
                                    <Accordion atomic={true}>
                                        {props.jobsEN.edges.map(jobs => {
                                            if(jobs.node.acf.jobLink){
                                                return(
                                                    <div className='jobsAlternateTitle' key={jobs.node.id}>
                                                        <a href={jobs.node.acf.jobLink} target="_blank">
                                                            {jobs.node.title}
                                                            <span className='afterSvg'>
                                                                <svg width="17px" height="17px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg"><path d="m630 0-140 17.5 42.664 42.664-184.85 184.85 37.188 37.188 184.85-184.85 42.664 42.664 17.5-140zm-525 52.5c-8.4531 0-18.324 3.0117-25.164 9.8516-6.8438 6.8438-9.8516 16.695-9.8516 25.164v437.5c0 8.4531 3.0117 18.324 9.8516 25.164 6.8438 6.8438 16.695 9.8516 25.164 9.8516h437.5c8.4531 0 18.324-3.0117 25.164-9.8516 6.8438-6.8438 9.8516-16.695 9.8516-25.164v-262.5h-52.5v245h-402.5v-402.5h262.5v-52.5h-280z"/></svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                )   
                                            }else{
                                                return(
                                                    <AccordionItem title={jobs.node.title} key={jobs.node.id}>
                                                        <div className="content" dangerouslySetInnerHTML={{__html:jobs.node.content}} />
                                                    </AccordionItem>
                                                
                                                )   
                                            }
                                        })}
                                    </Accordion>
                                </Fade>
                                )
                            }
                    }
                    if(pageContext.node.languageCode === 'ko'){
                        const valueCheck = props.jobsKO.edges
                        if( valueCheck.length != 0 )
                        {
                            return(
                            <Fade bottom speed={450} delay={25}>
                                <h2>Current openings</h2>
                                <Accordion atomic={true}>
                                    {props.jobsKO.edges.map(jobs => {
                                        if(jobs.node.acf.jobLink){
                                            return(
                                                <div className='jobsAlternateTitle' key={jobs.node.id}>
                                                    <a href={jobs.node.acf.jobLink} target="_blank">
                                                        {jobs.node.title}
                                                        <span className='afterSvg'>
                                                            <svg width="17px" height="17px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg"><path d="m630 0-140 17.5 42.664 42.664-184.85 184.85 37.188 37.188 184.85-184.85 42.664 42.664 17.5-140zm-525 52.5c-8.4531 0-18.324 3.0117-25.164 9.8516-6.8438 6.8438-9.8516 16.695-9.8516 25.164v437.5c0 8.4531 3.0117 18.324 9.8516 25.164 6.8438 6.8438 16.695 9.8516 25.164 9.8516h437.5c8.4531 0 18.324-3.0117 25.164-9.8516 6.8438-6.8438 9.8516-16.695 9.8516-25.164v-262.5h-52.5v245h-402.5v-402.5h262.5v-52.5h-280z"/></svg>
                                                        </span>
                                                    </a>
                                                </div>
                                            )   
                                        }else{
                                            return(
                                                <AccordionItem title={jobs.node.title} key={jobs.node.id}>
                                                    <div className="content" dangerouslySetInnerHTML={{__html:jobs.node.content}} />
                                                </AccordionItem>
                                            
                                            )   
                                        }
                                    })}
                                </Accordion>
                            </Fade>
                            )
                        }
                    }
                }} />
            </section>
        </article>
    </Layout>
)}