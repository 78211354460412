import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
const Spotlight = props => {
    return(
        <section className="spotlight">
          {props.image && 
            <img src={props.image} alt="" />
          }
            {/* <StaticImage src={props.image} alt="" /> */}
        </section>
    )
}

export default Spotlight